import React, { useState, useEffect } from 'react';
import { toggleRule, toggleFlagRule } from 'src/store/actions/ruleAsyncActions';
import { number, bool, object, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import classes from './ruleToggle.css';
import treeTypes from 'src/constants/treeTypes';
import { Toggle } from 'fronds/Controllers';

function RuleToggle(props) {
    const { id, enabled, dates, treeType } = props;
    let dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(enabled);
    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() => {
        setIsChecked(enabled);
    }, [enabled]);
    async function handleToggle() {
        setIsDisabled(true);
        const newState =
            treeType === treeTypes.flag
                ? await dispatch(toggleFlagRule(id, { enabled: !enabled }))
                : await dispatch(toggleRule(id, { dates, enabled: !enabled }));
        setIsChecked(newState);
        setIsDisabled(false);
    }
    return (
        <div className={isDisabled ? classes.wait : null}>
            <Toggle
                checked={isChecked}
                onChange={() => handleToggle()}
                disabled={isDisabled}
            />
        </div>
    );
}

RuleToggle.propTypes = {
    id: number,
    enabled: bool,
    treeType: string,
    dates: object
};

export default RuleToggle;
